import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from "@ngrx/store";
import { SessionState } from "./session.reducer";
import { SessionService } from "./session.service";
import * as SessionActions from './session.actions';
import { environment } from '../../environments/environment';
import { EMPTY, of, Observable, from } from 'rxjs';
import { IApiSession, ILoginError } from '../interfaces/session.api.interface';
import {
  catchError,
  map,
  concatMap,
  tap,
  map as rxMap,
  withLatestFrom,
  filter,
} from 'rxjs/operators';

@Injectable()
export class SessionEffects{

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private router: Router,
        private state: Store<{ session: SessionState }>,
        private session: SessionService
      ) {
      }

      storeSession$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(SessionActions.storeSession),
          concatMap(({ session }) => {
            try {
              if (session) {
                localStorage.setItem('session', JSON.stringify(session));
              } else {
                localStorage.clear();
              }
              return of(SessionActions.storeSessionSuccess());
            } catch (error) {
              console.error(error);
              return of(SessionActions.storeSessionFailure({ error }));
            }
          })
        );
      });

    login$ = createEffect(() => {
      console.log("Login effect: " + `${environment.api}/session/signIn`)
        return this.actions$.pipe(
          ofType(SessionActions.login),
          concatMap(({ credentials }) => 
            this.http.post<IApiSession>(`${environment.api}/session/signIn`, credentials).pipe(
              map(session => 
                SessionActions.loginSuccess({ session })),                            
              catchError(error => 
                of(SessionActions.loginFailure({ error })))
            )
          )
        );
      });

      
//{error: true, errorMessage : null} as ILoginError
      loginSuccess$ = createEffect(() => {
        console.log("Login success effect")
        return this.actions$.pipe(
          ofType(SessionActions.loginSuccess),
          tap( res => 
            console.log('login success:', res)),
          map(({ session }) => {
            return SessionActions.storeSession({ session });
          })
        );
      });


      loginFailures$ = createEffect(() => {
        console.log("Login fail effect")
        return this.actions$.pipe(
          ofType(SessionActions.loginFailure),
          tap( res => 
            console.log('login fail:', res)),
          map(({ error }) => {
            return SessionActions.storeSession({ session:null });
          })
        );
      });  


      logout$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(SessionActions.logout),
          concatMap(() => this.http.delete(`${environment.api}/session`)),
          map(() => SessionActions.logoutSuccess()),
          catchError((err) => of(SessionActions.logoutFailure(err)))
        );
      });
    
      logoutSuccess$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(SessionActions.logoutSuccess),
          map(() => SessionActions.storeSession({ session: null })),
          tap((data) => { this.router.navigate(['/account/login']); })
        );
      });

      logoutFailure$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(SessionActions.logoutFailure),
          map(() => SessionActions.storeSession({ session: null }))
        );
      });

}