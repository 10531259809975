import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSession from './session.reducer';

export const selectSessionState = createFeatureSelector<fromSession.SessionState>(
  fromSession.sessionFeatureKey
);

export const getSessionUser = createSelector(
  selectSessionState,
  (state: fromSession.SessionState) => (state?.session ? state?.session?.User : null)
);


export const getSessionError = createSelector(
  selectSessionState,
  (state: fromSession.SessionState) => (state?.loginError ? state?.loginError : null)
);