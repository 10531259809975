import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { L10nConfig, L10nProvider, L10nTranslationLoader } from 'angular-l10n';
import { Observable } from 'rxjs';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable() export class HttpTranslationLoader implements L10nTranslationLoader {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(@Optional() private http: HttpClient) { }

  public get(language: string, provider: L10nProvider): Observable<{ [key: string]: any }> {
      const url = `${provider.asset as string}-${language}.json`;
      const options = {
          headers: this.headers,
          params: new HttpParams().set('v', provider.options.version)
      };
      return this.http.get(url, options);
  }
}

export const l10nConfig: L10nConfig = {
  format: 'language',
  providers: [
    { name: 'app', asset: './assets/i18n/locale', options: { version: '1.0.0' } }
  ],
  cache: true,
  keySeparator: '.',
  defaultLocale: { language: 'es', currency: 'EUR' },
  schema: [
    { locale: { language: 'es', currency: 'EUR' }, dir: 'ltr', text: 'España' },
    { locale: { language: 'en', currency: 'EUR' }, dir: 'ltr', text: 'United Kingdom' }
  ],
};

const spanishRangeLabel = (page: number, pageSize: number, length: number) => {
  return `Página ${page + 1} de ${length > 0 ? Math.ceil(length / pageSize) : 1}`;
}


export function getSpanishPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();
  
  paginatorIntl.itemsPerPageLabel = 'Elementos por página:';
  paginatorIntl.nextPageLabel = 'Página siguiente';
  paginatorIntl.previousPageLabel = 'Página anterior';
  paginatorIntl.lastPageLabel = 'Página final';
  paginatorIntl.firstPageLabel = 'Página inicial';
  paginatorIntl.getRangeLabel = spanishRangeLabel;
  
  return paginatorIntl;
}