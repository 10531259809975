import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/utilities/session/session.service';
import { LoaderService } from './loader.service';
import { delay } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit{
  title = 'client';

  constructor(public sessionService: SessionService, private router: Router, public loading: LoaderService) { }

  ngOnInit() {
  }
}
