import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggedOutGuard } from './Logged-out.guard';
import { LoggedInGuard } from './logged-in.guard';
import { APP_ENVIRONMENT } from '../../utilities/services/environment.injection-token';
import { environment } from '../../environments/environment';
import { L10nTranslationModule } from 'angular-l10n';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import {RouterModule} from '@angular/router';
import { UtilsService } from './utils.service';
import { LocationsLateralMenuComponent } from './locations-lateral-menu/locations-lateral-menu.component';
import { DialogMessageComponent } from './dialog/dialog-message/dialog-message.component';
import { DialogService } from './dialog/dialog.service';
import { DialogYesNoComponent } from './dialog/dialog-yes-no/dialog-yes-no.component';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LocationsLateralMenuComponent,
    DialogMessageComponent,
    DialogYesNoComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LocationsLateralMenuComponent    
  ],
  imports: [
    RouterModule,
    CommonModule,
    L10nTranslationModule,
    MatDialogModule   
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        LoggedInGuard,
        LoggedOutGuard,
        [{ provide: APP_ENVIRONMENT, useValue: environment }],
        UtilsService,
        DialogService
      ]
    };
  }
 }
