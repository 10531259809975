import { Injectable } from "@angular/core";
import { UserRole } from "../interfaces/user.api.interface";
import { SessionService } from "../session/session.service";

@Injectable({ providedIn: 'root' })
export class MenuConfig {

  //Submenu configuración
  configurationModules: IMenuItem[] = [
    { name: 'users', routerLink: ['/users'], session: 'with', icon: '', roles: [UserRole.Admin], childItems: []  },
    { name: 'projects', routerLink: ['/projects'], session: 'with', icon: '', roles: [UserRole.Admin], childItems: []  },
    { name: 'locations', routerLink: ['/countries'], session: 'with', icon: '', roles: [UserRole.Admin], childItems: []  },
    { name: 'settingValues', routerLink: ['/settingValues'], session: 'with', icon: '', roles: [UserRole.Admin], childItems: []  },
	  { name: 'primaryAdministrators', routerLink: ['/primaryAdministrators'], session: 'with', icon: '', roles: [UserRole.Admin], childItems: []  },
    { name: 'trusts', routerLink: ['/trusts'], session: 'with', icon: '', roles: [UserRole.Admin], childItems: []  },
    { name: 'fileComparator', routerLink: ['/fileComparator'], session: 'with', icon: '', roles: [UserRole.Admin], childItems: []  },
  ]

  //Submenu postfondeo
  postfundingModules: IMenuItem[] = [
    { name: 'postfundingFiles', routerLink: ['/postfundingFiles'], session: 'with', icon: '', roles: [UserRole.Admin, UserRole.Backoffice], childItems: []  },
    { name: 'postfundingCreditsFinal', routerLink: ['/postfundingCreditsFinal'], session: 'with', icon: '', roles: [UserRole.Admin, UserRole.Backoffice], childItems: []  },
  ]


  /*Array menu principal */
  menuModules: IMenuItem[] = [
    { name: 'prefunding', routerLink: ['/prefundingFiles'], session: 'with', icon: '', roles: [UserRole.Admin, UserRole.Backoffice, UserRole.Prefunding], childItems: []},
    { name: 'postfunding', routerLink: [], session: 'with', icon: '', roles: [UserRole.Admin, UserRole.Backoffice, UserRole.Prefunding], childItems: this.postfundingModules},
    { name: 'configuration', routerLink: [], session: 'with', icon: '', roles: [UserRole.Admin], childItems: this.configurationModules }
  ];

  /**
   * Devuelve los modulos de la aplicación
   */
  private GetModules(): IMenuItem[] {
    return this.menuModules;
  }

  /**
   * Devuelve las opciones de menu disponibles para un usuario
   */
  public GetMenuOptions(sessionService: SessionService): IMenuItem[] {
    var options = this.GetModules();
    const isAdmin = sessionService.isAdmin();

    if (sessionService.isLoggedIn()) {
      var userRole = sessionService.user.rol;
      options = options.filter(item => {
        return (!item.roles || item.roles.includes(userRole)) && ((!item.session) || (item.session === 'with') || (isAdmin));
      });
    } else { 
      options = options.filter(item => (!item.session || item.session === 'without'));
    }

    return options;
  }

  /**
   * Devuelve las opciones de menu disponibles para un usuario logeado
   */
  public GetFirstMenuOptions(sessionService: SessionService): IMenuItem {
    var options = this.GetModules();
    const isAdmin = sessionService.isAdmin();
    var userRole = sessionService.user.rol;

    options = options.filter(item => {
      return (!item.roles || item.roles.includes(userRole)) && ((!item.session) || (item.session === 'with') || (isAdmin));
    });

    var firstOption = options[0];
    
    //Si tiene elementos de segundo nivel, buscamos la primera opción en el nivel
    if (firstOption.childItems != null && firstOption.childItems.length > 0){
      var secondLevelOptions = firstOption.childItems.filter(item => {
        return (!item.roles || item.roles.includes(userRole)) && ((!item.session) || (item.session === 'with') || (isAdmin));
      });
      firstOption = secondLevelOptions != null && secondLevelOptions.length > 0 ? secondLevelOptions[0] : firstOption;
    }

    return firstOption;
  }


  public hasChildItems(menuItem: IMenuItem)
  {
    return menuItem.childItems != null && menuItem.childItems.length > 0
  }

  public GetLiClass(menuItem: IMenuItem) : string
  {
    return this.hasChildItems(menuItem) ? "dropdown-menu dropdown-menu-default dropdown-user-responsive" : "";
  }

  public GetLiAttr(menuItem: IMenuItem) : string
  {
    return this.hasChildItems(menuItem) ? "dropdown-menu dropdown-menu-default dropdown-user-responsive" : "";
  }


}

export interface IMenuItem {
  name: string;
  shortName?: string;
  routerLink: any[];
  session?: 'with' | 'without';
  icon: string;
  roles?: number[]
  childItems: IMenuItem[]
}

