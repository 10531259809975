import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, flatMap, catchError, shareReplay, mergeMap } from 'rxjs/operators';

import { SessionService } from '../session/session.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EnvironmentConfig } from '../interfaces/environment.interface';
import { APP_ENVIRONMENT } from './environment.injection-token';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  public loginRoute = '/account/login';
  public refreshRoute = `${this.environment.api}/session/refreshToken`;

  constructor(
    private session: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(APP_ENVIRONMENT) private environment: EnvironmentConfig
    ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return next.handle(req).pipe(
      // tap(event => console.log('HTTP EVENT', JSON.stringify(event))),
      catchError(event => {
        if (event instanceof HttpErrorResponse && event.status === 401) {
          if (this.session.user) {
            console.log('401 found, trying refresh token');
            const refReq = req.clone({
              method: 'POST',
              body: null,
              url: this.refreshRoute,
              withCredentials: true,
              params: new HttpParams()
            });
            // const refReq = new HttpRequest('POST', this.refreshRoute);
            refReq.headers.append('Content-Type', 'application/json;charset=utf-8');
            return next.handle(refReq);
          } else {
                void this.router.navigate([this.loginRoute]);
          }
        }
        return throwError(event);
      }),
      catchError(refreshError => {
        if (refreshError instanceof HttpErrorResponse && refreshError.url.includes(this.refreshRoute)) {
          this.session.logout();
          void this.router.navigate([this.loginRoute]);
        }
        return throwError(refreshError);
      }),
      mergeMap(event => {
        if (
          event instanceof HttpResponse &&
          event.status === 200 &&
          event.url.includes(this.refreshRoute)
        ) {
          return next.handle(req);
        }
        return of(event);
      }),
      map((res: any) => {        
        if (
          res instanceof HttpResponse &&
          !!res.url &&
          res.url.indexOf(this.environment.api) >= 0 &&
          res.body.data
        ) {
          res = res.clone({ body: res.body.data });
          // return res.body ? res.body.data ? res.body.data : res.body : res;
        }
        return res;
      })
    );
  }
}
