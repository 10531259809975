import { Action, createReducer, on } from '@ngrx/store';
import * as SessionActions from './session.actions';
import { IApiSession, ILoginError } from '../interfaces/session.api.interface';

export const sessionFeatureKey = 'session';

export interface SessionState {
    session: IApiSession;
    loginError: ILoginError;
  }
  
  export const initialState: SessionState = {
    session: null,
    loginError: null
  };

const sessionReducer = createReducer(
  initialState,
  on(SessionActions.loginSuccess, (state, action) => ({ ...state, session: action.session, loginError: initialState.loginError })),
  on(SessionActions.loginFailure, (state, action) => 
  ({ ...state, session: initialState.session, loginError: {error: true}  as ILoginError})),
  on(SessionActions.logoutSuccess, state => ({ ...state, session: initialState.session, loginError: initialState.loginError  })),
  on(SessionActions.logoutFailure, state => ({ ...state, session: initialState.session, loginError: initialState.loginError })),
);

export function reducer(state: SessionState | undefined, action: Action): SessionState {
  return sessionReducer(state, action);
}
