import { IApiEntity } from "./entity.api.interface";

/**
 * Interfaz con los datos que se envian y se reciben de los servicios
 */
export interface IApiUser extends IApiEntity {
  idUser?: number;
  name?: string;
  lastname1?: string;
  lastname2?: string;
  identityCard?: string;
  phone?: string;
  mobile?: string;
  mail?: string;
  idCountry?: number;
  idProvince?: number;
  idMunicipality?: number;
  idTown?: number;
  address?: string;
  zipcode?: string;
  login?: string;
  password?: string;
  rol?: number;
  state?: boolean;
  comments?: string;
  currentPage?: number;
  elementsPerPage? : number;
}

/**
 * Lista de perfiles disponibles
 */
export enum UserRole{
  Admin = 10, // Admin
  Backoffice = 20, // Backoffice
  Prefunding = 30 // Prefondeo
}

/**
 * Lista de estados disponibles
 */
export enum UserStates{
  Active = 1, // Activo
  Inactive = 0 // Inactivo
}