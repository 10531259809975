import { Component, OnDestroy, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { IDialogMessage } from './dialog.message.interface';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss']
})
export class DialogMessageComponent implements OnDestroy {

  private contentSubscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public content: IDialogMessage) {

  }

  ngOnDestroy(): void {
    if (this.contentSubscription != null && this.contentSubscription != undefined) {
      this.contentSubscription.unsubscribe();
    }
  }

}
