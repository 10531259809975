import { Component, Inject, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { IMenuItem } from 'src/utilities/menu/menu';

@Component({
  selector: 'app-locations-lateral-menu',
  templateUrl: './locations-lateral-menu.component.html',
  styleUrls: ['./locations-lateral-menu.component.scss']
})
export class LocationsLateralMenuComponent implements OnInit {

  ListMenuLocations: IMenuItem[] = [
    
    {name: 'titleCountry', routerLink: ['/countries'], session: 'with', icon: '', roles:[], childItems:[]},
    {name: 'titleRegion', routerLink: ['/regions'], session: 'with', icon: '', roles:[], childItems:[]},
    {name: 'titleTown', routerLink: ['/towns'], session: 'with', icon: '', roles:[], childItems:[]},
    {name: 'titleCity', routerLink: ['/cities'], session: 'with', icon: '', roles:[], childItems:[]}
  ]
  

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private translation: L10nTranslationService) { }

  ngOnInit() {
  }

}
