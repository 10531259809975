<div class="row pbxl mll">
  <div class="col-12">
    <div class="accordion" id="accordionMenu">
      <div class="accordion-item">
        <div class="portlet light">
          <div class="portlet-title title-reduced title-color">
            <div class="caption ptn pbn">
              <button class="accordion-button caption-subject font-blue bold uppercase pln pts pbs" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" translate>$locations.titleMenu</button>
            </div>
          </div>
          <div class="portlet-body ptn collapse show" id="collapseOne" data-bs-target="#accordionMenu">
            <div class="list-group" >
              <a *ngFor="let valueMenu of ListMenuLocations" class="list-group-item list-group-item-action" [routerLink]="valueMenu.routerLink" routerLinkActive="active" translate>$locations.{{valueMenu.name}}</a>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>