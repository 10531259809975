import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from 'src/app/shared/logged-in.guard';


const routes: Routes = [
  { path: '', redirectTo: '/users/list', pathMatch: 'full' },
  { path: 'account', loadChildren: () => import('./account/account.module').then((m) => m.AccountModule) },
  { path: 'users', canActivate: [LoggedInGuard], loadChildren: () => import('./users/users.module').then((m) => m.UsersModule) },
  { path: 'settingValues', canActivate: [LoggedInGuard], loadChildren: () => import('./settingValues/setting-values.module').then((m) => m.SettingValuesModule)},
  { path: 'projects', canActivate: [LoggedInGuard], loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule) },
  { path: 'primaryAdministrators', canActivate: [LoggedInGuard], loadChildren: () => import('./primaryAdministrators/primaryAdministrators.module').then((m) => m.PrimaryAdministratorsModule) },
  { path: 'trusts', canActivate: [LoggedInGuard], loadChildren: () => import('./trusts/trusts.module').then((m) => m.TrustsModule) },
  { path: 'countries', canActivate: [LoggedInGuard], loadChildren: () => import('./countries/countries.module').then((m) => m.CountriesModule) },
  { path: 'regions', canActivate: [LoggedInGuard], loadChildren: () => import('./regions/regions.module').then((m) => m.RegionsModule) },
  { path: 'towns', canActivate: [LoggedInGuard], loadChildren: () => import('./towns/towns.module').then((m) => m.TownsModule) },
  { path: 'cities', canActivate: [LoggedInGuard], loadChildren: () => import('./cities/cities.module').then((m) => m.CitiesModule) },
  { path: 'fileComparator', canActivate: [LoggedInGuard], loadChildren: () => import('./fileComparator/file-comparator.module').then((m) => m.FileComparatorModule) },
  { path: 'prefundingFiles', canActivate: [LoggedInGuard], loadChildren: () => import('./prefundingFiles/prefundingFiles.module').then((m) => m.PrefundingFilesModule) },
  { path: 'postfundingFiles', canActivate: [LoggedInGuard], loadChildren: () => import('./postfundingFiles/postfundingFiles.module').then((m) => m.PostfundingFilesModule) },
  { path: 'postfundingCredits', canActivate: [LoggedInGuard], loadChildren: () => import('./postfundingCredits/postfundingCredits.module').then((m) => m.PostfundingCreditsModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
