import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from 'src/utilities/session/session.service';



@Injectable()
export class LoggedInGuard implements CanActivate {
  
  constructor(private sessionService: SessionService, private router: Router) { }

  canActivate(): boolean {
    if (!!this.sessionService.isLoggedIn()) {
      return true;
    } else {
      console.log('logged in guard redirecting to login');
      void this.router.navigate(['/account/login']);
      return false;
    }
  }
}
