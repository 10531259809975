<div class="page-header d-flex align-items-center" *ngIf="!!sessionService.isLoggedIn()">
    <div class="page-header-top">
        <div class="container-fluid">
            <div class="page-logo d-flex align-items-center">
                <a [routerLink]="['/account/index']"><img class="logo-default" src="../../../assets/img/logo.png" alt="{{'$general.fullProjectName' | translateAsync}}"/></a>                
            </div>
            <a href="javascript:;" class="menu-toggler" onclick="handleMenuToggler()"><i class="fa fa-bars font-hg"></i></a>
            <div class="page-header-menu">
                <ul class="nav navbar-nav float-end">
                    <li *ngFor="let menuItem of menuOptions" [ngClass]="{'dropdown': hasChildItems(menuItem)}" routerLinkActive="active">
                        <ng-container 
                            [ngTemplateOutlet]="hasChildItems(menuItem) ? menuItemDropdown : menuItemLink" 
                            [ngTemplateOutletContext]="{menuItem:menuItem}">
                        </ng-container>
                    </li>
                    <li class="dropdown dropdown-menu-user">
                        <a href="javascript:;" class="dropdown-toggle a-avatar" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img alt="" class="img-avatar" src="../../../assets/img/avatar.png">
                        </a>
                        <ul class="dropdown-menu">
                            <li>
                                <a class="dropdown-item" title="Salir" translate (click)="logout()"><i class="icon-logout"></i>$menu.logout</a>
                            </li>
                        </ul>
                    </li>                    
                </ul>
            </div>
        </div>
    </div>
  </div>


<ng-template #menuItemDropdown let-menuItem='menuItem'>
    <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" translate>
        $menu.{{ menuItem.name }}
    </a>
    <ul class="dropdown-menu">
        <li *ngFor="let childItem of menuItem.childItems" routerLinkActive="active">
            <a [routerLink]="childItem.routerLink" routerLinkActive="active" class="dropdown-item" type="button" translate>
                $menu.{{ childItem.name }}
            </a>
        </li>
    </ul>
</ng-template>

<ng-template #menuItemLink let-menuItem='menuItem'>
    <a [routerLink]="menuItem.routerLink" routerLinkActive="active" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" translate>
        $menu.{{ menuItem.name }}
    </a>
</ng-template>
  