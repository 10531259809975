import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDialogMessage } from '../dialog-message/dialog.message.interface';

@Component({
  selector: 'app-dialog-yes-no',
  templateUrl: './dialog-yes-no.component.html',
  styleUrls: ['./dialog-yes-no.component.scss']
})
export class DialogYesNoComponent {

  constructor( public dialog: MatDialogRef<DialogYesNoComponent>, @Inject(MAT_DIALOG_DATA) public content: IDialogMessage) {
  }

  noResponse() {
    this.dialog.close(false);
  }

  yesResponse() {
    this.dialog.close(true);
  }

}
