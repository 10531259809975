<mat-dialog-content>
	<div class="card rounded-3">
		<div class="card-header card-header-modal d-flex align-content-center justify-content-between bg-white" *ngIf="content.title">
			<h5 class="modal-title" translate>{{ content.title }}</h5>
			<button type="button" class="btn-close" (click)="noResponse()"></button>
		</div>
		<div class="card-body">
			{{ content.message }}
		</div>
		<div class="card-footer bg-white">
			<div class="text-end card-footer-actions">
				<button class="btn btn-sm btn-primary rounded-pill ps-3 pe-3 text-uppercase mb-1" (click)="yesResponse()" translate>$general.btnYes</button>
        <button class="btn btn-sm btn-primary rounded-pill ps-3 pe-3 text-uppercase mb-1" (click)="noResponse()" translate>$general.btnNo</button>		
			</div>
		</div>
	</div>
</mat-dialog-content>