import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { IMenuItem, MenuConfig } from 'src/utilities/menu/menu';
import { SessionService } from 'src/utilities/session/session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {

  private menuConfig: MenuConfig

  constructor(public sessionService: SessionService, @Inject(L10N_LOCALE) public locale: L10nLocale, private translation: L10nTranslationService) {
    this.menuConfig = new MenuConfig();
   }

  ngOnInit(): void {
  }

  get menuOptions(): IMenuItem[] {
    let options = this.menuConfig.GetMenuOptions(this.sessionService);
  
    return options;
  }


  public hasChildItems(menuItem: IMenuItem)
  {
    return menuItem.childItems != null && menuItem.childItems.length > 0
  }


  /**
   * Retorna el nombre del proyecto
   */
  getProjectName() : string{
    return this.translation.translate("$general.fullProjectName")
  }


  logout(): void {
    this.sessionService.logout();
  }

}
