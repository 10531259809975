import { MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';
import { RouterStateTitle } from 'src/utilities/router-state-serializer';

export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];

export interface State {
  router: fromRouter.RouterReducerState<RouterStateTitle>;
}

export const reducers = {
  router: fromRouter.routerReducer,
};

// While we won't be using this in this post,
// selectors provide an easy way to access pieces of store using store.select(SELECTOR) to return an Observable of
// that state subset and only emit a new value if it changes.
export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<RouterStateTitle>
>('router');
