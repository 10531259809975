import { Injectable } from "@angular/core";
import { IApiUser, UserRole } from "../interfaces/user.api.interface";
import { Store } from "@ngrx/store";
import * as SessionActions from './session.actions';
import * as SessionSelectors from './session.selectors';
import { IApiLoginCredentials, IApiSession, ILoginError } from "../interfaces/session.api.interface";
import { Observable, Subject, shareReplay } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SessionService {

    //Usuario de sessión
    private currentUser : IApiUser;

    private logoutSource = new Subject<void>();

    constructor(private store: Store<IApiUser>){
        this.loadSession();

        this.sessionUser$().subscribe(user => {
            this.currentUser = user;
          });

          
    }

    private loadSession(): void {
        try {
          if (typeof localStorage !== 'undefined') {
            
            const session = JSON.parse(localStorage.getItem('session')) as IApiSession;
            this.store.dispatch(SessionActions.loginSuccess({ session }));
          }
        } catch (err) {
          console.error(err);
          var error = {error : true, errorMessage : null} as ILoginError;
          this.store.dispatch(SessionActions.loginFailure({error}));
        }
      }

    logout(): void {
      this.store.dispatch(SessionActions.logout());
      this.logoutSource.next();
    }

    private sessionUser$(): Observable<IApiUser> {
        return this.store.select(SessionSelectors.getSessionUser).pipe(shareReplay());
    }

    sessionUserChanges$(): Observable<IApiUser> {
      return this.store.select(SessionSelectors.getSessionUser);
    }

    sessionLoginErrorChanges$(): Observable<ILoginError> {
      return this.store.select(SessionSelectors.getSessionError);
    }

    

    /*
    *    Getter User
    */
    get user(): IApiUser {
        return this.currentUser;
    }
    
    /**
     * Comprueba si el usuario está conectado
     * @returns true if logged
     */
    isLoggedIn(): boolean {
        return !!this.currentUser;
    }

    /**
     * Comprueba si es administrador
     */
    isAdmin(): boolean {
      return this.currentUser?.rol == UserRole.Admin;
    }


    /**
     * Disparador de la accion Login
     * @param credentials Datos de las credenciales
     */
    login(credentials: IApiLoginCredentials): void {
      this.store.dispatch(SessionActions.login({ credentials }));
    }

}