import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { SessionEffects } from './session.effects';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import * as fromSession from './session.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    EffectsModule.forFeature([SessionEffects]),
    StoreModule.forFeature(fromSession.sessionFeatureKey, fromSession.reducer)
  ],
  providers: []
})
export class SessionModule {}
